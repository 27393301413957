export const userInfoKeymap = {
  userID: 'User ID',
  userName: 'Username',
  firstName: 'First Name',
  lastName: 'Last Name',
  email: 'Email',
  createdOn: 'Created',
  language: 'Language',
  userState: 'User Status',
  lastDateAttempted: 'Last Login Attempted',
  lastDateSucceded: 'Last Login Succeeded',
  failedAttemptCount: 'Failed Login Attempts Since Successful',
  idVerified: 'ID Verified',
  phoneVerified: 'Phone Verified',
  certifiedTech: 'Certified Tech',
  certTechExpirationDate: 'Tech Cert Expires',
  certTechIssueDate: 'Tech Cert Issued',
  certifiedInfo: 'Verification Sessions'
};

export const languageMap = {
  en: 'English (en)',
  es: 'Spanish (es)',
  fr: 'French (fr)',
  fr_ca: 'French Canadian (fr_ca)',
  it: 'Italian (it)',
  de: 'German (de)',
  pt_br: 'Portuguese (pt_br)',
  ja: 'Japanese (ja)',
  ko: 'Korean (ko)',
  zh: 'Chinese (zh)',
};

export const shopStateInfoKeymap = {
  shopState: 'Shop',
  shopSubscriptionState: 'AA Subscription',
  shopPaymentNotificationState: 'AA Payment Notification',
  braintreeSubscriptionState: 'AA Braintree Subscription',
};

export const crmStateInfoKeymap = {
  crmState: 'CRM',
  crmSubscriptionState: 'CRM Subscription',
  crmFreeTrialUsed: 'CRM Free Trial',
  shopCRMSubNotificationState: 'CRM Payment Notification',
  crmBraintreeSubscriptionState: 'CRM Braintree Subscription',
}

export const addressInfoKeymap = {
  streetAddress1: 'Street Address 1',
  streetAddress2: 'Street Address 2',
  city: 'City',
  state: 'State',
  zip: 'Zip',
  country: 'Country',
  phone: 'Phone',
};

export const shopInfoKeymap = {
  ...addressInfoKeymap,
  shopName: 'Shop Name',
}

// These are the possible fields in the 'changes' object of log entries.
export const orderInfoKeymap = {
  technicianID: 'Assigned Tech ID',
  customerID: 'Customer ID',
  customer: 'Customer Name',
  vehicleID: 'Vehicle ID',
  vehicle: 'Vehicle',
  title: 'Order Title',
  status: 'Order Status',
  description: 'Description',
  technician: 'Assigned Tech Name',
}

// These are the possible fields in the 'changes' object of log entries.
export const eventInfoKeymap = {
  orderID: 'Order ID',
  technicianID: 'Assigned Tech ID',
  startDate: 'Event Start',
  endDate: 'Event End',
  highlighterColor: 'Event Color',
  title: 'Event Title',
  allDay: 'All Day',
  description: 'Description',
  eventType: 'Event Type',
}

// These are the possible fields in the 'changes' object of log entries.
export const vehicleInfoKeymap = {
  make: 'Make',
  model: 'Model',
  year: 'Year',
  color: 'Color',
  licensePlate: 'License Plate',
  odometer: 'Odometer',
  vin: 'VIN',
}

// These are the possible fields in the 'changes' object of log entries.
export const customerInfoKeymap = {
  firstName: 'First Name',
  lastName: 'Last Name',
  email: 'Email',
  phoneNumber: 'Phone',
  contactPreference: 'Contact Preference',
  language: 'Language',
  crmSmsDisclaimer: 'SMS Disclaimer',
}

export const paymentInfoKeymap = {
  customerId: 'Customer ID',
  token: 'Token',
  subscriptionId: 'AA Subscription ID',
  planId: 'AA Plan ID',
  status: 'AA Status',
  nextBillAmount: 'AA Next Bill',
  newBillAmount: 'AA New Bill Amount',
  nextBillingDate: 'AA Next Bill Date',
  paidThroughDate: 'AA Paid Thru'
};

export const crmPaymentInfoKeymap = {
  crmSubscriptionId: 'CRM Subscription ID',
  crmPlanId: 'CRM Plan ID',
  crmStatus: 'CRM Status',
  crmNextBillAmount: 'CRM Next Bill',
  crmNextBillingDate: 'CRM Next Bill Date',
  crmPaidThroughDate: 'CRM Paid Thru'
};

export const toolInfoKeymap = {
  toolManufacturer: 'Manufacturer',
  toolModel: 'Model',
  toolSerial: 'Serial Number',
  toolName: 'Tool Name',
  toolNotes: 'Tool Notes',
  toolID: 'Tool ID',
  shopID: 'Shop ID',
  addedBy: 'Added By',
  addedOn: 'Added On',
  toolModelUUID: 'Model UUID',
  toolManufacturerUUID: 'Manufacturer UUID',
  vin: 'VIN',
  currentShopID: 'Current Shop ID',
  isCurrentlyAssignedToShop: 'Is Currently Assigned to Shop',
  lastADExecuteDate: 'Last ADExecute Date',
  lastADInitiateDate: 'Last ADInitiate Date',
  lastADRequestDate: 'Last ADRequest Date',
  lastErrorDate: 'Last Error Date',
  totalADExecuteEvents: 'Total ADExecute Events',
  totalADInitiateEvents: 'Total ADInitiate Events',
  totalADRequestEvents: 'Total ADRequest Events',
  totalToolErrorEvents: 'Total Tool Error Events'
};
